var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        dialogClass: "translate-modal",
        visible: _vm.visible,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [_vm._v("新建背景册")]
      ),
      _c(
        "div",
        { style: _vm.backCss, on: { mouseover: _vm.topCenterMouseover } },
        [
          _vm.visible
            ? _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "form",
                    staticStyle: { "padding-bottom": "15px" },
                    attrs: { model: _vm.formData, rules: _vm.rules },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "章节序号",
                        prop: "chapterNo",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: true,
                            "option-filter-prop": "label",
                            "show-search": "",
                            placeholder: "请选择章节",
                          },
                          on: { change: _vm.chapterChange },
                          model: {
                            value: _vm.formData.chapterNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "chapterNo", $$v)
                            },
                            expression: "formData.chapterNo",
                          },
                        },
                        [
                          _vm._l(_vm.chapterList, function (chapter) {
                            return _c(
                              "a-select-option",
                              {
                                key: chapter.sequenceNo,
                                attrs: { value: chapter.sequenceNo },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(chapter.sequenceNo) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          _vm.chapterList.every(
                            (chapter) =>
                              chapter.sequenceNo != _vm.formData.chapterNo
                          ) && _vm.formData.chapterNo
                            ? _c(
                                "a-select-option",
                                { attrs: { value: _vm.formData.chapterNo } },
                                [_vm._v("\n            章节已删除\n          ")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "文本框",
                        prop: "chapterNo",
                      },
                    },
                    [
                      _c(
                        "a-spin",
                        { attrs: { spinning: _vm.selectLoading } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                dropdownClassName: "textArea-select",
                                disabled: true,
                                "show-search": "",
                                "option-filter-prop": "label",
                                placeholder: "请选择文本框",
                              },
                              on: { change: _vm.textAreaChange },
                              model: {
                                value: _vm.formData.textAreaId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "textAreaId", $$v)
                                },
                                expression: "formData.textAreaId",
                              },
                            },
                            _vm._l(_vm.textAreaList, function (textArea) {
                              return _c(
                                "a-select-option",
                                {
                                  key: textArea.id,
                                  attrs: {
                                    label: textArea.label,
                                    value: textArea.id,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(textArea.label) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "origin-item",
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "原文图片",
                        prop: "originImgUrl",
                      },
                    },
                    [
                      _c(
                        "a-upload-dragger",
                        {
                          attrs: {
                            name: "file",
                            accept: ".jpg,.png",
                            showUploadList: false,
                            action: _vm.ossUploadUrl,
                            data: _vm.ossUploadData,
                            "custom-request": _vm.handleUpload,
                          },
                          on: { change: _vm.handleUploadChange },
                        },
                        [
                          !_vm.formData.originImgUrl
                            ? _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "ant-upload-drag-icon" },
                                  [_c("a-icon", { attrs: { type: "inbox" } })],
                                  1
                                ),
                                _c("p", { staticClass: "ant-upload-text" }, [
                                  _vm._v(
                                    "\n              拖拽或点击上传\n            "
                                  ),
                                ]),
                                _c("p", { staticClass: "ant-upload-hint" }, [
                                  _vm._v(
                                    "\n              支持JPG、PNG\n            "
                                  ),
                                ]),
                              ])
                            : _c("img", {
                                staticStyle: {
                                  "max-width": "300px",
                                  "max-height": "117px",
                                },
                                attrs: {
                                  src: _vm.formData.originImgUrl,
                                  alt: "",
                                },
                              }),
                          _vm.formData.originImgUrl
                            ? _c("a-icon", {
                                attrs: { type: "close-circle" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.clearUrl.apply(null, arguments)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.visible
                        ? _c("Editor", {
                            ref: "editor",
                            staticClass: "img-editor",
                            attrs: {
                              defaultConfig: _vm.editorConfig,
                              mode: _vm.mode,
                            },
                            on: {
                              onCreated: _vm.onCreated,
                              customPaste: _vm.customPaste,
                            },
                            model: {
                              value: _vm.originImgUrl,
                              callback: function ($$v) {
                                _vm.originImgUrl = $$v
                              },
                              expression: "originImgUrl",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "原文",
                        prop: "originContent",
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          disabled:
                            _vm.isRejectStatus &&
                            !_vm.selectTextAreaData[0].isReject,
                          rows: 5,
                        },
                        model: {
                          value: _vm.formData.originContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "originContent", $$v)
                          },
                          expression: "formData.originContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "译文",
                        prop: "processedContent",
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          disabled:
                            _vm.isRejectStatus &&
                            !_vm.selectTextAreaData[0].isReject,
                          rows: 5,
                        },
                        model: {
                          value: _vm.formData.processedContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "processedContent", $$v)
                          },
                          expression: "formData.processedContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c(
              "a",
              {
                staticStyle: { float: "left" },
                on: { click: _vm.handleAfter },
              },
              [_vm._v("继续新建")]
            ),
            _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.handleOk },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }