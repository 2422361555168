<template>
  <a-modal :maskClosable="false" dialogClass="translate-modal" :visible="visible" @ok="handleOk" @cancel="handleCancel">
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <div @mouseover="topCenterMouseover" slot="title" class="modal-title">新建背景册</div>
    <div @mouseover="topCenterMouseover" :style="backCss">
      <a-form-model
        v-if="visible"
        ref="form"
        style="padding-bottom:15px"
        :model="formData"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="章节序号" prop="chapterNo">
          <a-select
            @change="chapterChange"
            style="width:100%"
            v-model="formData.chapterNo"
            :disabled="true"
            option-filter-prop="label"
            show-search
            placeholder="请选择章节"
          >
            <a-select-option v-for="chapter in chapterList" :key="chapter.sequenceNo" :value="chapter.sequenceNo">
              {{ chapter.sequenceNo }}
            </a-select-option>
            <a-select-option
              v-if="chapterList.every(chapter => chapter.sequenceNo != formData.chapterNo) && formData.chapterNo"
              :value="formData.chapterNo"
            >
              章节已删除
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="文本框" prop="chapterNo">
          <a-spin :spinning="selectLoading">
            <a-select
              style="width:100%"
              @change="textAreaChange"
              dropdownClassName="textArea-select"
              :disabled="true"
              v-model="formData.textAreaId"
              show-search
              option-filter-prop="label"
              placeholder="请选择文本框"
            >
              <a-select-option
                :label="textArea.label"
                v-for="textArea in textAreaList"
                :key="textArea.id"
                :value="textArea.id"
              >
                {{ textArea.label }}
              </a-select-option>
            </a-select>
          </a-spin>
        </a-form-model-item>
        <a-form-model-item
          class="origin-item"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="原文图片"
          prop="originImgUrl"
        >
          <a-upload-dragger
            name="file"
            accept=".jpg,.png"
            :showUploadList="false"
            :action="ossUploadUrl"
            :data="ossUploadData"
            :custom-request="handleUpload"
            @change="handleUploadChange"
          >
            <div v-if="!formData.originImgUrl">
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                拖拽或点击上传
              </p>
              <p class="ant-upload-hint">
                支持JPG、PNG
              </p>
            </div>
            <img v-else style="max-width:300px;max-height:117px" :src="formData.originImgUrl" alt="" />
            <a-icon v-if="formData.originImgUrl" @click.stop="clearUrl" type="close-circle" />
          </a-upload-dragger>
          <Editor
            v-if="visible"
            ref="editor"
            class="img-editor"
            v-model="originImgUrl"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            @customPaste="customPaste"
          />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="原文" prop="originContent">
          <a-textarea
            v-model="formData.originContent"
            :disabled="isRejectStatus && !selectTextAreaData[0].isReject"
            :rows="5"
          />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="译文" prop="processedContent">
          <a-textarea
            v-model="formData.processedContent"
            :disabled="isRejectStatus && !selectTextAreaData[0].isReject"
            :rows="5"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <template slot="footer">
      <div @mouseover="topCenterMouseover">
        <a style="float:left" @click="handleAfter">继续新建</a>
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { postAction, putAction } from '@/api/manage'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { getAction, v1getAction } from '../../../api/manage'
export default {
  props: {
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: '#000000'
    },
    isRejectStatus: {
      type: Boolean,
      default: false
    }
  },
  components: { Editor, Toolbar },
  mixins: [UploadFileToOssMixins],
  data() {
    return {
      visible: false,
      rules: {
        chapterNo: [{ required: true, message: '请选择章节序号', trigger: 'change' }],
        originContent: [{ required: true, message: '请输入原文', trigger: 'blur' }],
        processedContent: [{ required: true, message: '请输入译文', trigger: 'blur' }],
        originImgUrl: [{ validator: this.validateOriginImgUrl }]
      },
      labelCol: {
        span: 7
      },
      formData: {
        originImgUrl: '',
        originContent: '',
        processedContent: '',
        textAreaId: undefined
      },
      wrapperCol: {
        span: 16
      },
      editorConfig: { placeholder: '拖拽或将图片粘贴到这里', MENU_CONF: { uploadImage: null } },
      editorConfig2: { placeholder: '拖拽或将图片粘贴到这里', MENU_CONF: { uploadImage: null } },
      editor: null,
      editor2: null,
      mode: 'default',
      wangeditorLoading: false,
      wangeditorLoading2: false,
      isEdit: false,
      originImgUrl: '',
      processedImgUrl: '',
      isChange: false,
      isChange2: false,
      labelWidth: '120',
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      fileList: [],
      uploadPath: 'glxt/default/termsBack',
      chapterList: [],
      textAreaList: [],
      selectLoading: false,
      diffData: null
    }
  },
  computed: {
    backCss() {
      return {
        '--theme-color': this.color
      }
    }
  },
  watch: {
    originImgUrl() {
      if (this.formData.originImgUrl) {
        this.editor.undo()
        if (this.isChange) {
          this.editor.dangerouslyInsertHtml(
            `<img src='${this.formData.originImgUrl}' style='width:356px;height:auto' />`
          )
        } else {
          this.editor.clear()
          if (this.curRow.originImgUrl) {
            this.formData.originImgUrl = this.curRow.originImgUrl
          } else {
            this.formData.originImgUrl = ''
          }
        }
        this.isChange = false
      } else {
        this.editor.undo()
      }
    },
    processedImgUrl() {
      if (this.formData.processedImgUrl) {
        this.editor2.undo()
        if (this.isChange2) {
          this.editor2.dangerouslyInsertHtml(
            `<img src='${this.formData.processedImgUrl}' style='width:356px;height:auto' />`
          )
        } else {
          this.editor2.clear()
          if (this.curRow.processedImgUrl) {
            this.formData.processedImgUrl = this.curRow.processedImgUrl
          } else {
            this.formData.processedImgUrl = ''
          }
        }
        this.isChange2 = false
      } else {
        this.editor2.undo()
      }
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    validateOriginImgUrl(rule, value, callback) {
      if (!this.formData.originImgUrl) {
        callback('请上传原文图片！')
      } else {
        callback()
      }
    },
    async handleUploadChange(info) {
      await this.beforeUpload(info.file.originFileObj)
      let formData = new FormData()
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', info.file.originFileObj)
      console.log(this.ossUploadData.host, 78)
      postAction(this.ossUploadData.host, formData).then(rest => {
        let params = this.getSaveFileParams(info.file.originFileObj)
        this.formData.originImgUrl = params.filePath
        this.originImgUrl = ''
        this.isChange = true
        this.wangeditorLoading = false
        this.$refs.form.validate()
      })
      return false
    },
    handleUpload(options) {
      return
    },
    textAreaChange() {
      this.formData.originContent = this.textAreaList.find(item => item.id == this.formData.textAreaId).originalText
      this.formData.processedContent = this.textAreaList
        .find(item => item.id == this.formData.textAreaId)
        .showText.replaceAll('\n ', '\n')
      this.diffData = JSON.parse(JSON.stringify(this.textAreaList.find(item => item.id == this.formData.textAreaId)))
    },
    async chapterChange() {
      this.selectLoading = true
      const res = await v1getAction(
        '/textarea/chapter/' + this.chapterList.find(c => c.sequenceNo == this.formData.chapterNo).chapterId
      )
      this.selectLoading = false
      if (res.code == 200) {
        this.textAreaList = []
        res.data.forEach(item => {
          item.textAreas.forEach(t => {
            this.textAreaList.push({
              ...t,
              label:
                '【' +
                item.pageSortOrderId +
                '-' +
                t.sequenceNo +
                '】' +
                (t.showText ? t.showText.replaceAll('\n ', '\n') : '')
            })
          })
        })
        this.formData.textAreaId = this.textAreaList[0].id
        this.formData.originContent = this.textAreaList[0].originalText
        this.formData.processedContent = this.textAreaList[0].showText.replaceAll('\n ', '\n')
        this.diffData = JSON.parse(JSON.stringify(this.textAreaList[0]))
      }
    },
    async open() {
      if (this.selectTextAreaData.length) {
        let params = JSON.parse(JSON.stringify(this.selectTextAreaData[0]))
        this.formData.originContent = params.originalText
        this.formData.processedContent = params.showText.replaceAll('\n ', '\n')
      }
      this.formData.chapterNo = this.taskDetail.chapterOrder - 0
      this.show()
      this.selectLoading = true
      const res = await getAction('/chapter/production/' + this.taskDetail.productionId)
      this.visible = true
      if (res.code == 200) {
        this.chapterList = res.data
        const rest = await v1getAction(
          '/textarea/chapter/' + this.chapterList.find(c => c.sequenceNo == this.formData.chapterNo).chapterId
        )
        this.selectLoading = false
        if (rest.code == 200) {
          this.textAreaList = []
          rest.data.forEach(item => {
            item.textAreas.forEach(t => {
              this.textAreaList.push({
                ...t,
                label:
                  '【' +
                  item.pageSortOrderId +
                  '-' +
                  t.sequenceNo +
                  '】' +
                  (t.showText ? t.showText.replaceAll('\n ', '\n') : '')
              })
            })
          })
          this.formData.textAreaId = this.selectTextAreaData[0].id
          this.diffData = JSON.parse(JSON.stringify(this.selectTextAreaData[0]))
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    clearUrl() {
      this.formData.originImgUrl = ''
    },
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    handleAfter() {
      let that = this
      if (this.formData.processedContent != this.diffData.showText.replaceAll('\n ', '\n')) {
        this.$confirm({
          title: '修改译文会重置译文样式与注音、着重号等数据，确认修改?',
          okText: '确认',
          cancelText: '取消',
          zIndex: 9999,
          async onOk() {
            that.btnLoading = true
            that.$refs.form.validate(async valid => {
              if (valid) {
                if (that.chapterList.every(chapter => chapter.sequenceNo != that.formData.chapterNo)) {
                  that.$message.error('章节已删除')
                  return
                }
                that.formData.type = '背景文字'
                that.formData.productionId = that.taskDetail.productionId
                const res = await postAction('/backgroundBook/create', that.formData)
                if (res.code == 200) {
                  that.$message.success('新建成功')
                  that.isSaved = true
                  that.btnLoading = false
                  that.$emit('backToText', {
                    originContent: that.formData.originContent || '',
                    processedContent: that.formData.processedContent
                  })
                  that.formData = {
                    originImgUrl: '',
                    originContent: '',
                    processedContent: '',
                    textAreaId: undefined
                  }
                  that.originImgUrl = ''
                  that.open()
                } else {
                  that.btnLoading = false
                  that.$message.error(res.msg || res.message)
                }
              } else {
                return false
              }
            })
          }
        })
      } else {
        this.btnLoading = true
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.formData.type = '背景文字'
            this.formData.productionId = this.taskDetail.productionId
            const res = await postAction('/backgroundBook/create', this.formData)
            if (res.code == 200) {
              this.$message.success('新建成功')
              this.isSaved = true
              this.btnLoading = false
              this.formData = {
                originImgUrl: '',
                originContent: '',
                processedContent: '',
                textAreaId: undefined
              }
              this.originImgUrl = ''
              this.open()
            } else {
              this.btnLoading = false
              this.$message.error(res.msg || res.message)
            }
          } else {
            return false
          }
        })
      }
    },
    handleOk() {
      this.save()
    },
    handleCancel() {
      this.visible = false
      this.initForm()
    },
    initForm() {
      this.formData = {
        originImgUrl: '',
        originContent: '',
        processedContent: '',
        textAreaId: undefined
      }
      this.originImgUrl = ''
      this.reCurRow = {}
      if (this.editor) this.editor.destroy()
      /* this.editor2.destroy() */
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    initE() {
      let that = this
      this.editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that.wangeditorLoading = true
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.originImgUrl = params.filePath
                that.editor.undo()
                /*                 that.editor.dangerouslyInsertHtml(
                  `<img src='${that.formData.originImgUrl}' style='width:356px;height:auto' />`
                ) */
                this.$refs.form.validate()
                that.isChange = true
                /* insertFn(policy.host, alt, href) */
                that.wangeditorLoading = false
              })
            })
        }
      }
    },
    initE2() {
      let that = this
      this.editorConfig2.MENU_CONF['uploadImage'] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          that.wangeditorLoading2 = true
          that
            .tokenAxios({
              url: '/oss',
              method: 'get'
            })
            .then(res => {
              let policy = {
                policy: res.data.policy,
                signature: res.data.signature,
                ossaccessKeyId: res.data.accessid,
                dir: res.data.dir,
                host: res.data.host,
                key: `${res.data.dir}${that.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
              }
              that.ossUploadData = policy
              that.ossFilePolicy[file.name] = policy
              let formData = new FormData()
              for (let key in policy) {
                formData.append(key, policy[key])
              }
              formData.append('file', file)
              postAction(policy.host, formData).then(rest => {
                console.log('成功OSS', policy.host)
                let params = that.getSaveFileParams(file)
                that.formData.processedImgUrl = params.filePath
                that.editor2.undo()
                /*                 that.editor2.dangerouslyInsertHtml(
                  `<img src='${that.formData.processedImgUrl}' style='width:356px;height:auto' />`
                ) */
                this.$refs.form.validate()
                that.isChange2 = true
                /* insertFn(policy.host, alt, href) */
                that.wangeditorLoading2 = false
              })
            })
        }
      }
    },
    customPaste(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.wangeditorLoading = true
          this.tokenAxios({
            url: '/oss',
            method: 'get'
          }).then(res => {
            let policy = {
              policy: res.data.policy,
              signature: res.data.signature,
              ossaccessKeyId: res.data.accessid,
              dir: res.data.dir,
              host: res.data.host,
              key: `${res.data.dir}${this.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
            }
            this.ossUploadData = policy
            this.ossFilePolicy[file.name] = policy
            let formData = new FormData()
            for (let key in policy) {
              formData.append(key, policy[key])
            }
            formData.append('file', file)
            postAction(policy.host, formData).then(rest => {
              console.log('成功OSS', policy.host)
              let params = this.getSaveFileParams(file)
              this.formData.originImgUrl = params.filePath
              console.log(this.formData.originImgUrl)
              this.editor.undo()
              this.$refs.form.validate()
              /*               this.editor.dangerouslyInsertHtml(
                `<img src='${this.formData.originImgUrl}' style='width:356px;height:auto' />`
              ) */
              this.isChange = true
              this.wangeditorLoading = false
            })
          })
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    customPaste2(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象', event)
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      /* editor.insertText('xxx') */

      // 返回 false ，阻止默认粘贴行为
      let items = event.clipboardData && event.clipboardData.items
      let file = null
      if (items && items.length) {
        // 检索剪切板items
        if (items[0].kind == 'file') {
          file = items[0].getAsFile()
          console.log(file)
          this.wangeditorLoading2 = true
          this.tokenAxios({
            url: '/oss',
            method: 'get'
          }).then(res => {
            let policy = {
              policy: res.data.policy,
              signature: res.data.signature,
              ossaccessKeyId: res.data.accessid,
              dir: res.data.dir,
              host: res.data.host,
              key: `${res.data.dir}${this.getGuid()}_${file.name.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g, '')}`
            }
            this.ossUploadData = policy
            this.ossFilePolicy[file.name] = policy
            let formData = new FormData()
            for (let key in policy) {
              formData.append(key, policy[key])
            }
            formData.append('file', file)
            postAction(policy.host, formData).then(rest => {
              console.log('成功OSS', policy.host)
              let params = this.getSaveFileParams(file)
              this.formData.processedImgUrl = params.filePath
              this.editor2.undo()
              /*               this.editor2.dangerouslyInsertHtml(
                `<img src='${this.formData.processedImgUrl}' style='width:356px;height:auto' />`
              ) */
              this.$refs.form.validate()
              this.isChange2 = true
              this.wangeditorLoading2 = false
            })
          })
        } else {
          this.$message.error('粘贴内容不是文件内容，请重新复制后粘贴')
        }
      }
      event.preventDefault()
      callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.formData.originImgUrl) {
        this.editor.clear()
        this.isChange = true
        this.editor.dangerouslyInsertHtml(`<img src='${this.formData.originImgUrl}' style='width:356px;height:auto' />`)
        this.editor.updateView()
      }
    },
    onCreated2(editor) {
      this.editor2 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      if (this.formData.processedImgUrl) {
        this.editor2.clear()
        this.isChange2 = true
        this.editor2.dangerouslyInsertHtml(
          `<img src='${this.formData.processedImgUrl}' style='width:356px;height:auto' />`
        )
        this.editor2.updateView()
      }
    },
    show() {
      console.log('显示...', { 'this.curRow': this.curRow })
      this.initE()
      this.initE2()
    },
    edit() {
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...')
      this.formData = this.curRow
      console.log({ 'this.formData': this.formData })
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.startEdit()
    },
    save() {
      this.btnLoading = true
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.isEdit) {
            this.formData.type = '背景文字'
            this.formData.productionId = this.taskDetail.productionId
            let that = this
            if (this.formData.processedContent != this.diffData.showText.replaceAll('\n ', '\n')) {
              this.$confirm({
                title: '修改译文会重置译文样式与注音、着重号等数据，确认修改?',
                okText: '确认',
                cancelText: '取消',
                zIndex: 9999,
                async onOk() {
                  const res = await postAction('/backgroundBook/create', that.formData)
                  if (res.code == 200) {
                    that.$message.success('新建成功')
                    that.isSaved = true
                    that.btnLoading = false
                    that.$emit('backToText', {
                      originContent: that.formData.originContent || '',
                      processedContent: that.formData.processedContent
                    })
                    that.initForm()
                    const editor = that.editor
                    if (editor == null) return
                    editor.destroy() // 组件销毁时，及时销毁编辑器
                    that.visible = false
                  } else {
                    that.btnLoading = false
                    that.$message.error(res.msg || res.message)
                  }
                },
                onCancel() {
                  console.log('Cancel')
                }
              })
            } else {
              const res = await postAction('/backgroundBook/create', that.formData)
              if (res.code == 200) {
                that.$message.success('新建成功')
                that.isSaved = true
                that.btnLoading = false
                that.$emit('backToText', {
                  originContent: that.formData.originContent,
                  processedContent: that.formData.processedContent
                })
                that.initForm()
                const editor = that.editor
                if (editor == null) return
                editor.destroy() // 组件销毁时，及时销毁编辑器
                that.visible = false
              } else {
                that.btnLoading = false
                that.$message.error(res.msg || res.message)
              }
            }
          } else {
            this.formData.productionId = this.parent.id
            const res = await postAction('/backgroundBook/update', this.formData)
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.isSaved = true
              this.btnLoading = false
              this.closed()
            } else {
              this.btnLoading = false
              this.$message.error(res.msg || res.message)
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .origin-item label::before {
  color: red !important;
}
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
/deep/ .img-editor {
  border: 1px solid gainsboro;
  transition: 0.3s all;
  margin-top: 15px;
  border-radius: 4px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid var(--theme-color);
  }
  p {
    margin: 0 !important;
    height: 32px;
    padding-top: 5px !important;
  }
}
/deep/ .w-e-text-placeholder {
  line-height: 1px !important;
  font-style: normal;
  color: rgba(0, 0, 0, 0.35);
}
/deep/ .w-e-text-container {
  border-radius: 4px !important;
}
.ant-upload .anticon-close-circle {
  position: absolute;
  top: 0;
  right: -16px;
  transition: 0.3s all;
  &:hover {
    color: var(--theme-color);
  }
}
</style>
